import React from 'react'

function TestimonialCard() {
  return (
  <div className="col-md-4">
    <div className="card">
      <div className="card-body">
        <img src='../assets/img/testimonials.png' width="88px" height="88" />
        <p className="card-text">
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante."
        </p>
        <p className="card-text"><strong>John Doe</strong></p>
      </div>
    </div>
  </div>
  )
}

export default TestimonialCard